<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>
          <b-col cols="5">
            <label>Tanggal Awal</label>
            <b-form-datepicker id="start-datepicker" v-model="startDateFilter" class="mb-2"></b-form-datepicker>
          </b-col>
          <b-col cols="5">
            <label>Tanggal Akhir</label>
            <b-form-datepicker id="end-datepicker" v-model="endDateFilter" class="mb-2"></b-form-datepicker>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center justify-content-center">
            <b-button variant="primary" @click="exportData()" :disabled="isLoadingExport">
              <feather-icon icon="DownloadIcon" /> Export
              <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
            </b-button>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refStockListTable"
        class="position-relative"
        :items="fetchStocks"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Branch -->
        <template #cell(Branch)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.branch.branch_name }} ({{ data.item.branch.branch_code }})</span>
          </div>
        </template>
        <!-- Column: Type -->
        <template #cell(Type)="data">
          <div class="text-nowrap">
            <span
v-if="data.item.stock_type=='IN'"
                class="align-text-top text-capitalize success"
>
              <feather-icon
                icon="ArrowDownIcon"
                size="16 "
                class="align-middle success"
              />
               {{ data.item.stock_type }}</span>
              <span
v-if="data.item.stock_type=='OUT'"
                class="align-text-top text-capitalize danger"
>
                <feather-icon
                icon="ArrowUpIcon"
                size="16"
                class="align-middle danger"
              /> {{ data.item.stock_type }}</span>
          </div>

        </template>
        <!-- Column: Description -->
        <template #cell(Description)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.stock_description }}</span>
          </div>
        </template>
        <!-- Column: TotalQty -->
        <template #cell(TotalQty)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.stock_total_qty }}</span>
          </div>
        </template>
        <!-- Column: DateCreated -->
        <template #cell(DateCreated)="data">
            {{ dateSimple(data.item.stock_created_time, 'YYYY-MM-DD HH:mm:ss') }}
        </template>
        <!-- Column: Action -->
        <template #cell(Action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail( data.item )">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalStocks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, VBModal, BListGroup, BListGroupItem, BFormDatepicker,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import StockList from './List'

const userRole = ''

const messageBox = ''
const to = ''
const cc = ''
const reason = ''
const StockId = ''
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    // StockesListFilters,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,

    vSelect,
  },
  data() {
    return {
    items,
    statusItems,
    StockId,
    userId,
    messagesList,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get('UserRole')
    this.$root.$on('refreshTable', text => {
    console.log(text) // here you need to use the arrow function
    this.$refs.refStockListTable.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-purchase-order'
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      })

    const isAddNewStockSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchStocks,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalStocks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStockListTable,
      refetchData,

      // UI
      resolveStockRoleVariant,
      resolveStockRoleIcon,
      resolveStockStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
    } = StockList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    return {
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewStockSidebarActive,
      userRole,
      fetchStocks,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalStocks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStockListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveStockRoleVariant,
      resolveStockRoleIcon,
      resolveStockStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
    }
  },
  methods: {
      deleteStock(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
          axios
              .get(`${process.env.VUE_APP_API_DELETE_MATERIAL_REQUEST}${id}`, { headers })
              .then(response => {
                console.log(response)
                if (response.data.success === true) {
                  this.$refs.refStockListTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editStock(data) {
          this.$cookies.set('StockEdit', data)
          this.$router.push({ name: 'apps-stock-edit' })
      },
      confirmStock(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const body = {
        time: moment(),
        }
    axios
      .post(`${process.env.VUE_APP_API_URL}/transactions/purchase_order/confirm/${id}`, body, { headers })
      .then(async response => {
        if (response.data.success === true) {
          this.$refs.refStockListTable.refresh()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Material Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      },
      sendStock(data) {
          this.$cookies.set('StockSend', data)
          this.$router.push({ name: 'apps-stock-send' })
      },
    addNew() {
          this.$router.push({ name: 'apps-stock-create' })
    },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    exportData() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_STOCKS}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilter,
            date_end: this.endDateFilter,
          },
      }).then(response => {
        console.log(response.data)
        console.log(response.headers)

        let filename = "report_stocks"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Import Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    showDetail(item) {
      this.$cookies.set('StockDetail', item)
      this.$router.push({ name: 'apps-stocks-details' })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get('userPermissions').split(',')
      return permissions.includes(permission) ? true : false
    },
    async receivePurchasingStock(item) {
      this.$cookies.set('StockDetail', item)
      this.$router.push({ name: 'apps-stock-receive-purchasing' })
    },
    async sendMessage() {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      const id = this.StockId
      const Message = this.messageBox
      const body = {
        purchase_order_chat_purchase_order_id: id,
        purchase_order_chat_text: Message,
        time: moment(),
      }
    axios
      .post(`${process.env.VUE_APP_API_CREATE_MATERIAL_REQUEST_CHATS}`, body, { headers })
      .then(async response => {
        if (response.data.success === true) {
          this.messageBox = ''
    axios
      .get(`${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`, { headers })
      .then(response2 => {
        if (response2.data.success === true) {
          console.log('Fetching Data')
      if (response2.data.data) {
      messagesList.splice(0, messagesList.length)
        response2.data.data.map(value => {
          messagesList.push(value)
          return value.invoiceMessage
        })
      }
        }
        })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
