import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useMaterialRequestsList() {
  const toast = useToast()
  const refStockListTable = ref(null)
  const tableColumns = [
    { key: 'Branch', sortable: false },
    { key: 'Type', sortable: true },
    { key: 'Description', sortable: true },
    { key: 'TotalQty', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'Action', sortable: true },
  ]
  const perPage = ref(10)
  const totalStocks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const startDateFilter = ref('')
  const endDateFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refStockListTable.value ? refStockListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStocks.value,
    }
  })

  const refetchData = () => {
    refStockListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc, startDateFilter, endDateFilter], () => {
    refetchData()
  })

  const fetchStocks = (ctx, callback) => {
    const to = perPage.value * (currentPage.value - 1)
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_STOCKS}?limit=${perPage.value}&offset=${to}&search=${searchQuery.value}&order_by=${sortBy.value}&order_sort=${isSortDirDesc.value ? 'DESC' : 'ASC'}&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}`, { headers })
      .then(response => {
        totalStocks.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchRoles = () => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ROLES}`, { headers })
      .then(response => {
        console.log(response)
        const temp = [
          { label: 'Admin', value: 'admin' },
          { label: 'Author', value: 'author' },
          { label: 'Editor', value: 'editor' },
          { label: 'Maintainer', value: 'maintainer' },
          { label: 'Subscriber', value: 'subscriber' },
        ]
        return temp
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching role list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchStocks,
    fetchRoles,
    tableColumns,
    perPage,
    currentPage,
    totalStocks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStockListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
    startDateFilter,
    endDateFilter,
  }
}
